import React, { useEffect, useState } from 'react';
import Header from '../header/Header';
import Main from '../main/Main';
import Viz from '../viz/Viz';
import './App.scss';

function App() {
  const [showResult, setShowResult] = useState(false);
  const [showViz, setShowViz] = useState(false);

  useEffect(() => {
    setShowViz(checkPath);
  }, []);

  const checkPath = () => {
    return window.location.pathname === '/viz';
  }
 
  const setResult = (result: boolean) => {
    setShowResult(result)
  }

  return (
    <div className="App">
      {!showViz && 
        <>
          <Header result={showResult}/>
          <Main result={(res: boolean) => setResult(res)}/>
        </>
      }
      {showViz && 
        <Viz />
      }
    </div>
  );
}

export default App;
