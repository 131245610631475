import React, { useEffect, useState } from 'react';
import {answersFirestore} from "../../firebase";
import './Viz.scss';

const Viz = () => {
    const [data, setData] = useState<firebase.firestore.DocumentData[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = answersFirestore.onSnapshot(snapshot => { 
            const d: firebase.firestore.DocumentData[] = [];
            snapshot.forEach(doc => { 
                d.push(doc.data()) ;
            });
            setLoading(false);
            setData(d);
        })

        return () => unsubscribe();
    },[]);

    const numberOfAnswers = () => {
        const dataWithContactInfo = data.filter((d) => typeof(d.contact) === "object");
        const dataWithoutContactInfo = data.filter(d => !dataWithContactInfo.map(dwci => dwci.id).includes(d.id));

        console.log(dataWithContactInfo);
        console.log(dataWithoutContactInfo);
    }

    return (
        <div>            
            {loading && 
                <div className="Viz">
                    Laddar
                </div>
            }
            {!loading && 
                <div className="Viz">
                    Data
                    {numberOfAnswers()}
                </div>
            }
        </div>
    )
}

export default Viz;