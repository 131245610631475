import React, { useState } from 'react';

import './Section.scss';
import { IQuestion } from '../interfaces/Interfaces';

const Section = (props: any) => {

    const skipAnswerCheck = false;

    const checkAnswered = () => {
        return (props.section.questions.filter((question: IQuestion) => question.answeredScale === -1).length <= 0) || skipAnswerCheck;
    }

    const [allIsAnswered, setAllIsAnswered] = useState(props.section.id === 0 ? true : checkAnswered);

    const getStyle = (index: number) => {
        return { "--id": index } as React.CSSProperties
    }

    const buttonStyle = "Section-right-buttons" + (props.section.id === 0 ? " disabled" : "");

    const inputChanged = (questionID: number, inputID: number) => {
        props.updateQuestion(props.section.id, questionID, inputID);
        setAllIsAnswered(checkAnswered);
    }

    const buttonForwardClass = "Section-left-buttons-forward" + (!allIsAnswered ? " disabled" : "");
 
    return (
        <>
            <div className="Section">
                <div className="Section-left">
                    <div className="Section-left-title" data-id={props.section.id === 0 ? '' : props.section.id.toString().padStart(2,'0')}>{props.section.name}</div>
                    <div className="Section-left-description">{props.section.description}</div>
                    <div className="Section-left-filler"></div>
                    <div className="Section-left-buttons">
                        <button className={buttonForwardClass} onClick={() => {if (allIsAnswered) props.updateID(props.section.id+1)}}>
                            <div className="Section-left-buttons-forward-content">
                                {props.section.id === 0 ? "Starta quiz" : (props.section.id === props.sections - 1 ? "Se resultat" : "Gå vidare")}
                            </div>
                        </button>
                        {props.section.id > 0 && 
                            <button className="Section-left-buttons-back b" onClick={() => props.updateID(props.section.id-1)}>
                                <div className="Section-left-buttons-back-content">Gå tillbaka</div>
                            </button>
                        }
                    </div>
                    </div>
                <div className="Section-right">
                    {props.section.background && 
                        <div className="Section-right-background">
                            <img src={require('../../images/'+props.section.background)}  className="Section-right-background-image" alt="Result"></img>
                        </div>
                    }

                    {props.section?.questions?.map((question: IQuestion, index: number) => (
                        <div key={index} className="Section-right-question" style={getStyle(index)}>
                            <div className="Section-right-question-scope">
                                {props.section.id.toString().padStart(2,'0')}{question.id.toString().padStart(2,'0')} - {question.scope === "now" ? "Nuläge" : "Målbild"}
                            </div>
                            <div className="Section-right-question-title">{question.question}</div>
                            <div className="Section-right-question-input">
                                {["Stämmer inte alls","Stämmer delvis","Stämmer ganska bra","Stämmer bra","Stämmer mycket bra"].map((value: string, id: number) => (
                                    <div key={id} className="Section-right-question-input-answer">
                                        <label>
                                            <input 
                                                type="radio"
                                                className="Section-right-question-input-answer-button"
                                                name={question.id.toString()} 
                                                value={id+1} 
                                                onChange={() => inputChanged(index+1, id+1)}
                                                defaultChecked={question.answeredScale-1 === id}
                                            />
                                            <div className="Section-right-question-input-answer-inner">
                                                <div className="Section-right-question-input-answer-inner-number">{id+1}</div>
                                                <div className="Section-right-question-input-answer-inner-text">{value}</div>
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className={buttonStyle}>
                        <button className={buttonForwardClass} onClick={() => {if (allIsAnswered) props.updateID(props.section.id+1)}}>
                            <div className="Section-right-buttons-forward-content">
                                {props.section.id === 0 ? "Starta quiz" : (props.section.id === props.sections - 1 ? "Se resultat" : "Gå vidare")}
                            </div>
                        </button>
                        {props.section.id > 0 && 
                            <button className="Section-right-buttons-back b" onClick={() => props.updateID(props.section.id-1)}>
                                <div className="Section-right-buttons-back-content">Gå tillbaka</div>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Section;