import React, { useState, useEffect } from 'react';
import {ILink} from '../interfaces/Interfaces';
import {answersFirestore, mailFirestore} from "../../firebase";
import {guid} from "../../id";

import './Result.scss';

const Result = (result: any) => {
    const [postingResults, setPostingResults] = useState(false);
    const [finishedPostingResults, setFinishedPostingResults] = useState(false);

    const skipAnswerCheck = false;

    const checkAnswered = () => {
        if (Array.from(document.querySelectorAll('.input-check')).length > 0) {
            const text = ((Array.from(document.querySelectorAll('.input-text')) as HTMLInputElement[]).map((el: HTMLInputElement) => el.value !== "").filter((val: boolean) => val === false).length <= 0);
            const check = (document.querySelector('.input-check') as HTMLInputElement).checked;

            return (text && check) || skipAnswerCheck;
        }
        else {
            return skipAnswerCheck
        }
    }

    const buttonClass = () => {
        return allIsAnswered ? (postingResults ? 'posting' : (finishedPostingResults ? 'finished' : '')) : 'disabled'
    }

    const [allIsAnswered, setAllIsAnswered] = useState(checkAnswered);
    const [buttonClassName, setButtonClassName] = useState(buttonClass);

    useEffect(() => {
        setButtonClassName(buttonClass);
    });

    const isEmptyOrSpaces = (str: string) => {
        return str === null || str.match(/^ *$/) !== null;
    }

    const postResults = () => {
        setPostingResults(true);

        const inputs = Array.from(document.querySelectorAll('.input')) as HTMLInputElement[];
        const item = {
            id: guid,
            contact: {
                name: !isEmptyOrSpaces(inputs[0].value) ? inputs[0].value : 'inget svar',
                surname: !isEmptyOrSpaces(inputs[1].value) ? inputs[1].value : 'inget svar',
                email: !isEmptyOrSpaces(inputs[2].value) ? inputs[2].value : 'inget svar',
                business: !isEmptyOrSpaces(inputs[3].value) ? inputs[3].value : 'inget svar',
                roll: !isEmptyOrSpaces(inputs[4].value) ? inputs[4].value : 'inget svar',
                phone: !isEmptyOrSpaces(inputs[5].value) ? inputs[5].value : 'inget svar'
            },
            answers: result.answers,
            result: result.result.resultTitle,
            date: Date.now()
        };

        answersFirestore.add(item)
        .then(() => {
            setPostingResults(false);
            setFinishedPostingResults(true);
            document.querySelector('.Result-overlay')?.classList.add('active');
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 500);
        });

        let htmlString = '<body><table style="width:100%;text-align:left;">';
        let sections = result.answers.sections.slice(1);

        sections.forEach((section: any) => {
            htmlString += '<tr><th>' +section.name + '</th></tr>';
            section.questions.forEach((question: any) => {
                htmlString += '<tr>';
                htmlString += '<th>' + question.question + '</th><th>' + question.answeredScale + '</th>';
                htmlString += '</tr>';
            });
            htmlString += '<tr><th></th></tr>';
        });

        htmlString += '<tr><th>Nuvärde (medel)</th><th>'+result.average.now+'</th></tr>';
        htmlString += '<tr><th>Målvärde (medel)</th><th>'+result.average.goal+'</th></tr>';
        htmlString += '<tr><th></th></tr>';
        htmlString += '<tr><th>Resultat</th><th>'+item.result+'</th></tr>';
        htmlString += '<tr><th></th></tr>';
        htmlString += '<tr><th>Namn</th><th>'+item.contact.name+'</th></tr>';
        htmlString += '<tr><th>Efternamn</th><th>'+item.contact.surname+'</th></tr>';
        htmlString += '<tr><th>E-post</th><th>'+item.contact.email+'</th></tr>';
        htmlString += '<tr><th>Företag</th><th>'+item.contact.business+'</th></tr>';
        htmlString += '<tr><th>Roll</th><th>'+item.contact.roll+'</th></tr>';
        htmlString += '<tr><th>Telefonnummer</th><th>'+item.contact.phone+'</th></tr>';
        htmlString += '</table></body>';

        const mail = {
            to: ['zington.sverige@gmail.com'],
            message: {
                subject: 'Zington Quiz - Ett svar har kommit in',
                text: 'Ett svar har kommit in från Zington Quiz',
                html: htmlString,
            },
            answers: result.answers
        };

        mailFirestore.add(mail);
    }

    return (
        <>
            <div className="Result">
                <div className="Result-left">
                    <div className="Result-left-title">
                        {result.result.resultTitle}
                    </div>
                    <div className="Result-left-description">
                    {result.result.result}
                    </div>
                    <div className="Result-left-linksTitle">
                        Kundcase
                    </div>
                    <div className="Result-left-linksText">
                        {result.result.caseText}
                    </div>
                    <div className="Result-left-links">
                        {result.result.links?.map((link: ILink, index: number) => (
                            <a href={link.url} target="_blank" rel="noopener noreferrer" key={index} className="Result-left-links-link">{link.name}</a>
                        ))}
                    </div>
                </div>
                <div className="Result-right">
                    {/* <div className="Result-right-image" style={getStyle(result.image)}></div> */}
                    <div className="Result-right-image">
                        <img src={require('../../images/'+result.result.image)} alt="Result"></img>
                    </div>
                    <div className="Result-right-title">
                        Ditt nästa steg
                    </div>
                    <div className="Result-right-description">
                        {/* Vill du veta mer om Zington som samarbetspartner för att ta nästa steg och utveckla den digitala plattformen?
                        Ange dina kontaktuppgifter så återkommer vi med en aktivitetsplan anpassad för just er. */}
                        {result.result.contactText}
                    </div>
                    <div className="Result-right-contact">
                        <div className="Result-right-contact-input">
                            <div className="Result-right-contact-input-row">
                                <div className="Result-right-contact-input-row-field">
                                    <div className="Result-right-contact-input-row-field-title">Förnamn</div>
                                    <input type="text" className="Result-right-contact-input-row-field-form input-text input" placeholder="Ditt förnamn" onChange={() => setAllIsAnswered(checkAnswered)}></input>
                                </div>
                                <div className="Result-right-contact-input-row-field">
                                    <div className="Result-right-contact-input-row-field-title">Efternamn</div>
                                    <input type="text" className="Result-right-contact-input-row-field-form input-text input" placeholder="Ditt efternamn" onChange={() => setAllIsAnswered(checkAnswered)}></input>
                                </div>
                            </div>
                            <div className="Result-right-contact-input-row">
                                <div className="Result-right-contact-input-row-field">
                                    <div className="Result-right-contact-input-row-field-title">E-postadress</div>
                                    <input type="email" className="Result-right-contact-input-row-field-form input-text input" placeholder="Din e-postadress" onChange={() => setAllIsAnswered(checkAnswered)}></input>
                                </div>
                                <div className="Result-right-contact-input-row-field">
                                    <div className="Result-right-contact-input-row-field-title optional">Företag</div>
                                    <input type="text" className="Result-right-contact-input-row-field-form input" placeholder="Företagsnamn (valfri)"></input>
                                </div>
                            </div>
                            <div className="Result-right-contact-input-row">
                                <div className="Result-right-contact-input-row-field">
                                    <div className="Result-right-contact-input-row-field-title">Roll</div>
                                    <input type="text" className="Result-right-contact-input-row-field-form input" placeholder="Din roll (valfri)"></input>
                                </div>
                                <div className="Result-right-contact-input-row-field">
                                    <div className="Result-right-contact-input-row-field-title">Telefonnummer</div>
                                    <input type="text" className="Result-right-contact-input-row-field-form input" placeholder="Ditt telefonnummer (valfri)"></input>
                                </div>
                            </div>
                            <div className="Result-right-contact-input-row">
                                <div className="Result-right-contact-input-row-field">
                                    <div className="Result-right-contact-input-row-field-title">Personuppgifter</div>
                                    <div className="Result-right-contact-input-row-field-check">
                                        <label className="Result-right-contact-input-row-field-check-label">
                                            <input type="checkbox" className="Result-right-contact-input-row-field-check-label-input input-check" onChange={() => setAllIsAnswered(checkAnswered)}></input>
                                            <div className="Result-right-contact-input-row-field-check-label-button">
                                                <div className="Result-right-contact-input-row-field-check-label-button-checkmark"></div>
                                            </div>
                                        </label>
                                        <div className="Result-right-contact-input-row-field-check-text">
                                            Jag har läst och godkänner 
                                            <a href="https://www.zingtongroup.com/information_om_personuppgiftsbehandling/" target="_blank" rel="noopener noreferrer"> så här behandlar vi dina personuppgifter</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="Result-right-contact-input-row">
                                <button className={buttonClassName} onClick={postResults}>
                                    <div className="Result-right-contact-input-row-text">Skicka in</div>
                                    <div className="Result-right-contact-input-row-inner"></div>
                                    <div className="Result-right-contact-input-row-inner2"></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Result-overlay actives">
                    <div className="Result-overlay-card">
                        <div className="Result-overlay-card-title">Tack!</div>
                        <div className="Result-overlay-card-text">Vi är glada att du tog dig tid, vi hör av oss.</div>
                        <a href="https://www.zingtongroup.com" target="_blank" rel="noopener noreferrer" className="Result-overlay-card-text-link">Zingtongroup.com</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Result;