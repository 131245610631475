import React, { useState, useEffect } from 'react';
import {ISections, ISection, IResults, IQuestion, IResult} from '../interfaces/Interfaces';
import Section from '../section/Section';
import Result from '../result/Result';
import questionsJSON from '../../files/questions.json';
import resultsJSON from '../../files/results.json';

import {answersFirestore} from "../../firebase";
import {guid} from "../../id";

import './Main.scss';

const Main = ({result}: any) => {
    const [questions, setQuestions] = useState<ISections>({"sections": null});
    const [results, setResults] = useState<IResults>({"results": null});
    const [finalResult, setFinalResult] = useState<any>({});
    const [showResult, setShowResult] = useState(false);
    const [activeID, setActiveID] = useState(0);

    useEffect(() => {
        setQuestions(questionsJSON);
        setResults(resultsJSON);
        result(showResult)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sectionProps = (section: ISection) => {
        return {
            updateID: (id: number) => updateSection(id),
            updateQuestion: (sectionID: number, questionID: number, inputID: number) => updateQuestionWithAnswer(sectionID, questionID, inputID),
            section: section,
            sections: questions.sections?.length
        }
    }

    const getResult = () => {
        const sections = questions.sections?.slice(1);
        if (sections) {
            const now = calculateAverage(sections, "now");
            const goal = calculateAverage(sections, "goal");
            const nowLow = now <= 3;
            const goalLow = goal <= 4;
            const result = results.results?.find((result: IResult) => result.nowLow === nowLow && result.goalLow === goalLow);
            if (result) {
                const resultAndAnswers = {
                    result: result,
                    answers: questions,
                    average: {
                        now: now,
                        goal: goal
                    }
                }
                return resultAndAnswers;
            }
        } else {
            throw new Error("Kapitel inte inlästa");
        }
        return {} as IResult
    }

    const calculateAverage = (sections: ISection[], scopeType: string) => {
        const scope = sections.reduce((acc: number, currSection: ISection) => {
            const scopeArray = currSection.questions?.filter((question: IQuestion) => question.scope === scopeType);
            if (scopeArray) {
                const scopeAverage = scopeArray.reduce((acc: number, curr: IQuestion) => acc + curr.answeredScale, 0) / scopeArray.length;
                return acc + scopeAverage;
            } else {
                return 0
            }
        }, 0) / sections.length;

        return scope
    }

    const updateSection = (id: number) => {
        setActiveID(id);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        if (questions?.sections && id >= questions.sections.length) {
            setFinalResult(getResult);
            setShowResult(true);
            result(true);
            const item = {
                id: guid,
                contact: 'inte ifyllt än',
                answers: questions,
                date: Date.now()
            };
            answersFirestore.add(item);
        }
    };

    const updateQuestionWithAnswer = (sectionID: number, questionID: number, inputID: number) => {
        // console.log(sectionID, squestionID, inputID);
        const question = questions.sections?.find((section: ISection) => section.id === sectionID)?.questions?.find((question: IQuestion) => question.id === questionID);
        if (question) {
            question.answeredScale = inputID;
        }
    };

    return (
        <>
            <div className="Main">
                {!showResult &&
                    <div className="Main-pagination">
                        {questions.sections?.map((value, index) => (
                            <div key={index} className={index === activeID ? "Main-pagination-page open" : "Main-pagination-page"} /*onClick={() => setActiveID(index)}*/></div>
                        ))}
                    </div>
                }

                {!showResult && 
                    <div className="Sections">
                        {questions.sections?.map((section, index) => (
                            (section.id === activeID && 
                                <div key={index}>
                                    <Section {...sectionProps(section)}/>
                                </div>
                            )
                            
                        ))}
                    </div>
                }
                {showResult && 
                    <div className="Results">
                        <Result {...finalResult}/>
                    </div>
                }
            </div>
        </>
    );
}

export default Main;