import React from 'react';
import './Header.scss';

const Header = (data: any) => {
    const headerClass = 'Header' + (data.result ? ' result' : '');

    return (
        <div className={headerClass}>
            <a href="/">
                <div className="logo"></div>
            </a>
        </div>
    );
}

export default Header;
